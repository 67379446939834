import React, { useEffect } from "react";
import SectionText from "../../../../components/SectionText";
import Image from "../../../../components/Image";

const Selectors = ({
  product,
  imageField,
  titleField,
  info,
  register,
  selectLabel,
  selectedOptionValue,
  setSelectValue,
  selectValues,
  radioLabel,
  radioValues,
  selectedRadioValue,
  setRadioValue,
  setValue
}) => {
  useEffect(() => {
    if (selectedOptionValue) {
      setValue("year", selectedOptionValue);
    }
    if (selectedRadioValue) {
      setValue("color", selectedRadioValue);
    }
  }, [selectedOptionValue, selectedRadioValue, setValue]);

  return (
    <div className={"form-selectors-container"}>
      <div className={"form-selectors-image-container"}>
        <Image
          data={{
            src: product ? product[imageField] : null,
            additionalClassNames: ["form-dynamic-image"]
          }}
        />
      </div>
      <SectionText
        data={{
          text: product ? product[titleField] : "",
          additionalClassNames: [
            "section-text",
            "form-selectors-title",
            "TEST"
          ],
        }}
      />
      <div className={"form-selectors-wrapper"}>
        {info && (
          <SectionText
            data={{
              text: info,
              additionalClassNames: [
                "section-text",
                "form-selectors-info"
              ],
            }}
          />
        )}
        <div className={"form-select"}>
          <label htmlFor="year" className={"form-selectors-label"}> {selectLabel} </label>
          <select { ...register("year", {
            onChange: (e) => setSelectValue(+e.target.value)
          })}>
            {selectValues && selectValues.map((item, index) => {
              return (
                <option
                  value={item}
                  key={index}
                  className={"form-select-option"}
                >
                  {item}
                </option>
              )
            })}
          </select>
        </div>
        <div className={"form-radio"}>
          <label htmlFor="color" className={"form-selectors-label"}> {radioLabel} </label>
          <span className={"form-radio-selector-inputs"}>
            {radioValues && radioValues.map((value, index) => (
              <input
                {...register("color", {
                  required: true,
                  onChange: (e) => setRadioValue(e.target.value)
                })}
                key={index}
                type="radio"
                value={value}
                id={value}
                className={"form-radio-input"}
              />
            ))}
          </span>
        </div>
      </div>
    </div>
  )
};

export default Selectors;