import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { SchemaContext } from "../../../packs/schema-context";
import Loader from "../../components/Loader";
import Pagination from "./components/Pagination";

export type Props = {
  customBusiness?: string;
  customProps: {
    ean: string;
    lot: string;
    business: string;
  };
  data: {
    callback: string;
    additionalClassNames: Array<String>;
    placeholderSearchText: string;
    searchButtonText: string;
    nothingFoundText: string;
    productKeyField: string;
    productPhotoField: string;
    productBrandField: string;
    productNameField: string;
    productTypeField: string;
    productInfoBgColor?: string;
    paginationColor?: string;
    paginationActiveItemBgColor?: string;
    searchBarColor?: string;
  };
};

const Catalog = ({ data, customBusiness, customProps }: Props) => {
  const {
    callback,
    additionalClassNames,
    placeholderSearchText,
    searchButtonText,
    nothingFoundText,
    productKeyField,
    productPhotoField,
    productBrandField,
    productNameField,
    productTypeField,
    paginationColor,
    productInfoBgColor,
    paginationActiveItemBgColor,
    searchBarColor,
  } = data;

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(window.history.state?.usr?.page || 1);
  const [isLoading, setIsLoading] = useState(false);
  const [countPages, setCountPages] = useState(1);
  const [searchValue, setSearchValue] = useState(
    window.history.state?.usr?.searchValue || ""
  );
  const [search, setSearch] = useState(
    window.history.state?.usr?.searchValue || ""
  );

  const { setSchema } = useContext(SchemaContext);
  const { business, ean } = useParams();
  const navigate = useNavigate();

  const fetchProductsList = async (pageNumber?: number) => {
    const response = await fetch(
      `/api/products/${customBusiness || business}/${
        ean || customProps?.ean
      }?&search_query=${search}&page=${pageNumber || page}`
    );
    return await response.json();
  };

  useEffect(() => {
    try {
      setIsLoading(true);
      fetchProductsList().then(({ products, count, URLLogoMarque }) => {
        setProducts(products);
        setIsLoading(false);
        setCountPages(count);
      });
    } catch (err) {
      console.log(`Error: ${err}`);
    }
  }, [business, page, search]);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchClick = async (e) => {
    e.preventDefault();
    try {
      window.history.replaceState(
        { usr: { searchValue, page: 1 } },
        document.title
      );
      setSearch(searchValue);
      setPage(1);
    } catch (err) {
      console.log(`Error: ${err}`);
    }
  };

  const handleClearClick = (e) => {
    e.preventDefault();
    setSearchValue("");
    if (search) {
      window.history.replaceState(
        { usr: { searchValue: "", page: 1 } },
        document.title
      );
      setSearch("");
      setPage(1);
    }
  };

  const goToLotPage = async (lot_number) => {
    try {
      const { root } = await (
        await fetch(`${callback}/${ean}/${lot_number}`, {
          method: "POST",
        })
      ).json();
      setSchema(root);

      navigate(lot_number, { state: { isFetched: true, page, searchValue } });
    } catch (err) {
      console.log(`Error: ${err}`);
    }
  };

  const getPlaceholderImage = (product) => {
    switch (product.traceType) {
      case "PRODUIT-AGEC":
        return "/images/restitution/agec/placeholder/tlc_placeholder.png";
      case "PRODUIT-ABJ":
        return "/images/restitution/agec/placeholder/abj_placeholder.png";
      case "PRODUIT-ASL":
        return "/images/restitution/agec/placeholder/asl_placeholder.png";
      case "PRODUIT-JOUET":
        return "/images/restitution/agec/placeholder/jouets_placeholder.png";
      case "PRODUIT-MEUBLE":
        return "/images/restitution/agec/placeholder/ea_placeholder.png";
      case "PRODUIT-PA":
        return "/images/restitution/agec/placeholder/pa_placeholder.png";
      case "PRODUIT-EEE":
        return "/images/restitution/agec/placeholder/eee_placeholder.png";
      case "PRODUIT-EMBALLAGE-PAPIER":
        return "/images/restitution/agec/placeholder/empap_placeholder.png";
      case "PRODUIT-PCHIM":
        return "/images/restitution/agec/placeholder/pchim_placeholder.png";
      case "DRAFT":
        return "/images/restitution/agec/placeholder/draft_restitution_image.png";
      default:
        return null;
    }
  };

  const content =
    Array.isArray(products) && products?.length > 0 ? (
      <div className={"catalog-content"}>
        {products?.map((item) => {
          return (
            <div
              className={"catalog-product-wrapper"}
              key={item[productKeyField]}
            >
              <div
                className={"catalog-product"}
                onClick={() => goToLotPage(item[productKeyField])}
              >
                <div className={"catalog-product-image-wrapper"}>
                  {item[productPhotoField] ? (
                    <img
                      className={"catalog-product-image"}
                      src={item[productPhotoField]}
                      alt={`${item[productNameField]} image`}
                    />
                  ) : (
                    <img
                      className={"catalog-product-image placeholder"}
                      src={getPlaceholderImage(item)}
                      alt={item[productNameField]}
                    />
                  )}
                </div>
                <div
                  className={"catalog-product-info"}
                  style={{ backgroundColor: productInfoBgColor }}
                >
                  <p className={"catalog-brand"}> {item[productBrandField]} </p>
                  <p className={"catalog-product-text"}>
                    {`${item[productNameField]} - ${item[productKeyField]}`}
                  </p>
                  <p className={"catalog-product-text"}>
                    {item[productTypeField]}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    ) : (
      <div className={"text-center"}> {nothingFoundText} </div>
    );

  return (
    <div className={`catalog-wrapper ${classNames(additionalClassNames)}`}>
      <form onSubmit={handleSearchClick} className={"search-wrapper"}>
        <input
          value={searchValue}
          placeholder={placeholderSearchText}
          type="text"
          onChange={handleChange}
          className={"search-input"}
          style={{ borderColor: searchBarColor }}
        />
        <button
          type="button"
          className={"clear-button"}
          style={{ display: !searchValue ? "none" : "block" }}
          onClick={handleClearClick}
        >
          <img src="/images/restitution/common/cross_icon.svg" alt="Clear" />
        </button>
        <button
          type="submit"
          className={"search-button"}
          style={{ borderColor: searchBarColor }}
        >
          {searchButtonText || (
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="122.879px"
              height="119.799px"
              viewBox="0 0 122.879 119.799"
              enableBackground="new 0 0 122.879 119.799"
              xmlSpace="preserve"
              fill={searchBarColor}
              className={"search-button-icon"}
            >
              <g>
                <path d="M49.988,0h0.016v0.007C63.803,0.011,76.298,5.608,85.34,14.652c9.027,9.031,14.619,21.515,14.628,35.303h0.007v0.033v0.04 h-0.007c-0.005,5.557-0.917,10.905-2.594,15.892c-0.281,0.837-0.575,1.641-0.877,2.409v0.007c-1.446,3.66-3.315,7.12-5.547,10.307 l29.082,26.139l0.018,0.016l0.157,0.146l0.011,0.011c1.642,1.563,2.536,3.656,2.649,5.78c0.11,2.1-0.543,4.248-1.979,5.971 l-0.011,0.016l-0.175,0.203l-0.035,0.035l-0.146,0.16l-0.016,0.021c-1.565,1.642-3.654,2.534-5.78,2.646 c-2.097,0.111-4.247-0.54-5.971-1.978l-0.015-0.011l-0.204-0.175l-0.029-0.024L78.761,90.865c-0.88,0.62-1.778,1.209-2.687,1.765 c-1.233,0.755-2.51,1.466-3.813,2.115c-6.699,3.342-14.269,5.222-22.272,5.222v0.007h-0.016v-0.007 c-13.799-0.004-26.296-5.601-35.338-14.645C5.605,76.291,0.016,63.805,0.007,50.021H0v-0.033v-0.016h0.007 c0.004-13.799,5.601-26.296,14.645-35.338C23.683,5.608,36.167,0.016,49.955,0.007V0H49.988L49.988,0z M50.004,11.21v0.007h-0.016 h-0.033V11.21c-10.686,0.007-20.372,4.35-27.384,11.359C15.56,29.578,11.213,39.274,11.21,49.973h0.007v0.016v0.033H11.21 c0.007,10.686,4.347,20.367,11.359,27.381c7.009,7.012,16.705,11.359,27.403,11.361v-0.007h0.016h0.033v0.007 c10.686-0.007,20.368-4.348,27.382-11.359c7.011-7.009,11.358-16.702,11.36-27.4h-0.006v-0.016v-0.033h0.006 c-0.006-10.686-4.35-20.372-11.358-27.384C70.396,15.56,60.703,11.213,50.004,11.21L50.004,11.21z" />
              </g>
            </svg>
          )}
        </button>
      </form>
      {!isLoading ? content : <Loader />}
      {!isLoading && products.length > 0 ? (
        <Pagination
          currentPage={page}
          onChange={setPage}
          totalPageCount={countPages}
          buttonConst={3}
          siblingCount={1}
          color={paginationColor}
          activeBgColor={paginationActiveItemBgColor}
        />
      ) : null}
    </div>
  );
};

export default Catalog;
