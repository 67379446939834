import React, { useEffect, useState } from "react";
import Builder, { BuilderComponent } from "../../Builder";
import classNames from "classnames";
import { checkImgExist } from "../../helpers";

export type Props = {
  /**
   * Object with all content for the container
   */
  data: {
    children: BuilderComponent[],
    additionalClassNames: Array<String>;
    customize?: boolean;
    product?: string;
    src?: string;
    business?: string;
    backgroundColor?: string;
    customInlineStyles?: object;
    isOptional?: {
      test: any;
    };
  };
  /**
   * Object containing functions or specific data for the current container
   */
  customProps?: object;
}

const SimpleContainer = ({ data, customProps, ...rest }: Props) => {
  const [backgroundImg, setBackgroundImg] = useState(null);
  const {
    children,
    additionalClassNames = [],
    customize,
    product,
    src,
    business,
    backgroundColor,
    customInlineStyles,
  } = data;
  const productName = product?.toLowerCase().replaceAll(' ', '_');

  if (data.isOptional && (!data.isOptional?.test || data.isOptional?.test.length === 0)) {
    return null;
  }

  useEffect(() => {
    if (customize) {
      const backgroundUrl = `${process.env.REACT_APP_BUCKET}${business}/v1/non-traced/${productName}.jpg`;
      business && checkImgExist(backgroundUrl)
          .then((result) => {
            setBackgroundImg(backgroundUrl);
          })
          .catch((e) => {
            console.log(`Can not load image ${backgroundUrl}`)
          });
    }
  }, [])

  const styles = customize ? ({
    ...(src && {backgroundImage: `url("${src}")`}),
    ...(backgroundColor && {backgroundColor}),
    ...(customInlineStyles && {...customInlineStyles}),
  }) : null;

  return (
    <div
      className={classNames(additionalClassNames)}
      style={{...styles, ...(backgroundImg && {backgroundImage: `url("${backgroundImg}")`})}}
    >
      {children?.map((child, index) => (
        <Builder
          key={`${index}_${Math.random()}`}
          schema={child}
          customProps={customProps}
          {...rest}
        />
      ))}
    </div>
  );
};

export default SimpleContainer;
