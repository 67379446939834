import React, { useEffect, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Builder from "../../Builder";
import Loader from "../../components/Loader";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { findSpecificIndex } from "../../helpers";

const site_id = document.getElementById('matomo_id')?.innerText;
const site_ids = document.getElementById('matomo_ids')?.innerText;

const BusinessProduct = ({ schema, setSchema, customBusiness, customProps }) => {
  const { business, product, ean, lot } = useParams();
  let loc = useLocation();
  const navigate = useNavigate();
  const { trackPageView, trackEvent, enableLinkTracking } = useMatomo();
  const hasBeenReached = useRef(false);

  if (site_id || site_ids) {
    enableLinkTracking();
  }

  useEffect(() => {
    const handleScroll = () => {
      const { innerHeight, scrollY } = window;
      const { offsetHeight } = document.body;
      const isAtBottom = innerHeight + scrollY + 1 >= offsetHeight;
      if (isAtBottom && !hasBeenReached.current) {
        trackEvent({
          category: 'Profondeur de défilement',
          action: 'Bas de page atteint',
          name: loc.pathname
        });
        hasBeenReached.current = true;
      }
    }
    if (site_id || site_ids) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [])

  useEffect(() => {
    if (site_id || site_ids) {
      trackPageView({});
      if (window.innerHeight >= document.body.offsetHeight && schema) {
        trackEvent({
          category: 'Profondeur de défilement',
          action: 'Bas de page atteint',
          name: loc.pathname
        });
        hasBeenReached.current = true;
      } else {
        hasBeenReached.current = false;
      }
    }
  }, [loc])

  useEffect(() => {
    async function fetchAPI() {
      const response = await fetch(
        lot
          ? `/api/schemas/${customBusiness || business}/${ean}/${lot}`
          : ean ? `/api/schemas/${customBusiness || business}/${ean}`
            : `/api/schemas/${customBusiness || business}/${product}/homepage`
      );

      if(!response.ok) {
        const message = await response.json();
        throw message || new Error();
      }

      const { root } = await response.json();

      if (root) {
        setSchema(root);
      }
    }

    if (!loc?.state?.isFetched) {
      fetchAPI()
        .catch((err) => {
          console.log('Error: ', err.error || err);

          // const businessName = customBusiness || business;
          // if (businessName === 'agec' && !!lot) {
          //   const currentPathname = loc.pathname;
          //   const index = findSpecificIndex(currentPathname, '/', 2);
          //   const catalogPage = `${currentPathname.slice(0, index)}${product ? '/' + product : ''}/${ean}`;
          //   // navigate(catalogPage, { state: { ...window.history.state?.usr, isFetched: false }});
          // }
        });
    } else {
      window.history.replaceState({ usr: { ...window.history.state?.usr, isFetched: false }}, document.title);
    }
  }, [business, product, lot]);

  return schema ? (
    <Builder
      schema={schema}
      setSchema={setSchema}
      customBusiness={customBusiness}
    />
  ) : (
    <div className={'loader-screen'}>
      <Loader />
    </div>
  )
};

export default BusinessProduct;
